import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SobreNos from "../../components/sobre-nos"
import BackgroundImage from "gatsby-background-image"
import { graphql, Link } from "gatsby"

const ReparoAirFryer = ({ data }) => {
  return (
    <Layout showHero={true}>
      <SEO title="Conserto e Limpeza De Air Fryer | Cedilar" />
      <SobreNos />
      <div className="container mx-auto justify-between">
        <h1 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Reparo e Limpeza de Air Fryer na Grande Região de Porto Alegre
        </h1>
        <p className="px-4 md:px-0 pb-6">
          Ter em casa equipamentos eletrodomésticos que facilitem ainda mais a
          sua vida na cozinha pode ser algo de extrema importância,
          principalmente se você é amante da praticidade aliada a manutenção da
          qualidade do sabor dos alimentos, podendo optar para isso com o uso da
          Air Fryer, peça indispensável na cozinha.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Existem vários benefícios de se ter uma Air Fryer em casa, como
          preparação rápida de alimentos com segurança e mais saúde, facilidade
          de limpeza, sem sujeira, com economia e sustentabilidade. Com tudo
          isso se tornou peça indispensável. Mas, e se ela falhar?
        </p>
        <p className="px-4 md:px-0 pb-6">
          Caso a sua Air Fryer apresente algum defeito, estaremos aqui para lhe
          ajudar no que for preciso, podendo contar com a nossa equipe de
          assistência técnica CeDilar trazendo sempre o melhor atendimento e
          assistência.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Quer saber mais sobre nossos serviços e como manter sua aumentar a
          vida útil da sua Air Fryer? Então, está no post certo. Vamos lá!
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Cuidados especiais com sua Air Fryer
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Para que a sua Air Fryer se mantenha sempre com a máxima qualidade
          possível nos processos que desenvolve o ideal é cuidar dela da forma
          correta, mantendo ela sempre limpa e evitando a utilização de
          substância na limpeza que podem prejudicar o seu equipamento.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Caso você não cuide bem da sua Air Fryer o tempo de vida útil dela
          diminui, assim provocando uma baixa qualidade no investimento que você
          fez na compra do produto. Caso a sua Air Fryer se mantenha suja por
          muito tempo, sem a manutenção e limpeza adequada, pode acabar
          prejudicando seu funcionamento e causando defeitos permanentes em suas
          peças.
        </p>
        <p className="px-4 md:px-0 pb-12">
          Procure ainda, em qualquer defeito, a busca por uma assistência de
          qualidade, especializada e ainda autorizada, reforçando assim o tempo
          de garantia de fábrica do produto e possibilitando que ele tenha a
          melhor assistência possível.
        </p>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="container mx-auto justify-between">
        <h2 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Mantendo a qualidade da sua Air Fryer
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Para que você possa manter sempre a qualidade da sua Air Fryer o ideal
          é ter consigo uma assistência especializada e de qualidade, como a
          CeDilar, que promove os cuidados necessários com seu equipamento
          favorecendo a vida útil do produto.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Atuamos há mais de 30 anos no mercado ofertando o que há de melhor em
          assistência técnica, com equipe preparada e totalmente capacitada para
          prestar o melhor serviço a você. Atuamos com atendimento a domicílio,
          bastando ligar para (51) 3217-9658 que iremos no seu endereço o mais
          rápido possível de acordo com sua necessidade.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Para a maior segurança dos seus equipamentos e ainda conferir a
          manutenção da garantia do mesmo, trabalhamos de forma autorizada pelas
          melhores marcas de eletrodomésticos do mercado, como Mondial, Cardal,
          Sintex, Lorenzetti, Hydra Corona e Philips Walita.
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Limpeza de Air Fryer
        </h2>
        <p className="px-4 md:px-0 pb-12">
          Como já dito anteriormente, caso a sua Air Fryer não esteja limpa como
          deve, pode acabar prejudicando no funcionamento do equipamento de
          forma permanente. E, para te ajudar por completo, oferecemos serviços
          não só de reparo e manutenção, mas ainda de{" "}
          <Link to="/" className="text-blue-600">
            limpeza da sua Air Fryer ,
          </Link>{" "}
          garantindo assim o máximo de vida-útil do seu equipamento com o melhor
          serviço.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default ReparoAirFryer
